import Link from "next/link";
import { FC, useEffect, useState } from "react";

import { Button } from "@aviary";
import { StickyNavigation, NavLinksWrapper, NavLink } from "@components";
import type { UserType } from "@types";

import LogoSvg from "../../../assets/svgs/fullscript-integrate-logo.svg";

import { NavbarDropdown } from "./NavbarDropdown";

import * as styles from "./PageHeader.styles";

const PageHeader: FC = () => {
  const [currentUser, setCurrentUser] = useState<UserType>(null);
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    const user: UserType = await fetch("/api/dashboard/users/current").then(res => res.json());

    setCurrentUser(user);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const renderNavLinks = () => (
    <NavLinksWrapper
      css={styles.navContainer}
      baseStyle={styles.navLink}
      activeStyle={styles.activeLink}
    >
      <NavLink
        href="/applications"
        subLinks={["/applications", "/application", "/myteam", "/account", "/user"]}
        title="Dashboard"
      />
      <NavLink href="/docs" title="Documentation" />
      <NavLink
        href="https://docs.google.com/forms/d/e/1FAIpQLSdReDkGQQQ35HmYU_67XTlPGn1sqpF2w60uiJMnfNB_83Bkjg/viewform"
        target="_blank"
        title="Support"
      />
    </NavLinksWrapper>
  );

  const renderNavAction = () => {
    if (loading) return null;

    if (currentUser?.email) {
      return <NavbarDropdown user={currentUser} />;
    } else {
      return (
        <Button href="/signin" isSize="small">
          Sign in
        </Button>
      );
    }
  };

  return (
    <header>
      <nav>
        <StickyNavigation>
          <div css={styles.wrapper}>
            <div css={styles.leftSection}>
              <Link href="/" passHref>
                <a css={styles.logoLink}>
                  <LogoSvg css={styles.logo} />
                </a>
              </Link>
              {renderNavLinks()}
            </div>
            {renderNavAction()}
          </div>
        </StickyNavigation>
      </nav>
    </header>
  );
};

export { PageHeader };
