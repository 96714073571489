import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import { FC, useState } from "react";

import { Dropdown, DropdownContent, DropdownItem, DropdownTrigger } from "@aviary";
import { OpenAction } from "@aviary/components/Dropdown";
import { UserAvatar } from "@components";

import type { UserType } from "../../../../types";

import * as styles from "./NavbarDropdown.styles";

interface Props {
  user: UserType;
}

const NavbarDropdown: FC<Props> = ({ user }) => {
  const router = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { firstName, lastName } = user || {};
  const fullName = `${firstName} ${lastName}`;

  const signOutUser = async () => {
    const res = await fetch("/api/dashboard/users/signout");

    if (res.ok) {
      router.reload();
    }
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      triggerCallback={() => setIsDropdownOpen(!isDropdownOpen)}
      openAction={OpenAction.CLICK}
    >
      <DropdownTrigger css={styles.dropdownTrigger}>
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          color="light"
          size="small"
          label={`Avatar for ${fullName}`}
        />
        <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} css={styles.icon} />
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem onSelect={() => router.push("/myteam")}>My team</DropdownItem>
        <DropdownItem onSelect={() => router.push("/user")}>My profile</DropdownItem>
        <DropdownItem onSelect={() => router.push("/account")}>Account</DropdownItem>
        <DropdownItem onSelect={signOutUser}>Sign out</DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};

export { NavbarDropdown };
