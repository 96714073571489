import { css } from "@emotion/react";

import { colors, dimensions } from "@styles";

export const wrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-inline: auto;
`;

export const logoLink = css`
  display: flex;
`;

export const logo = css`
  height: 2.5rem;
  width: 8rem;
  cursor: pointer;
  align-self: center;

  path:nth-of-type(odd) {
    stroke: ${colors.white};
    fill: ${colors.white};
  }
`;

export const leftSection = css`
  display: flex;
  height: 100%;
`;

export const navContainer = css`
  display: none;

  @media only screen and (min-width: ${dimensions.tablet}) {
    display: flex;
    margin-left: 2rem;
  }
`;

export const navLink = css`
  padding: 0 1rem;
  color: ${colors.sidebarLink};
  display: flex;
  height: 100%;
  align-items: center;
  border-bottom: 4px solid transparent;

  &:hover {
    color: ${colors.white};
  }
`;

export const activeLink = css`
  color: ${colors.white};
  border-bottom: 4px solid ${colors.green.darkFamily.normal};
`;
