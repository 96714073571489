import { css } from "@emotion/react";

import { colors } from "@styles";

export const dropdownTrigger = css`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const icon = css`
  margin-left: 0.5rem;

  path {
    color: ${colors.white};
  }
`;
